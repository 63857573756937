import React from 'react';

import { 
  Checkbox,
  Spinner, 
  InputGroup, 
  Button,
  Dialog,
  Callout,
  HTMLSelect,
  Classes,
} from '@blueprintjs/core';

import axios from 'axios';

import errorHandler from './errorHandler';

export default class BatchAssignComponent extends React.Component {

  state = {
    showAssign: false,
    drivers: [],
    selectedDriver: null,
  }

  componentDidMount() {
    axios.get(`/api/drivers`).then((resp) => {
      this.setState({
        drivers: resp.data,
      })
    }).catch(errorHandler);
  }

  toggleAssign = () => {
    this.setState(({showAssign}) => ({
      showAssign: !showAssign
    }))
  }

  doSelect = (e) => {

    this.setState({
      loading: true,
    });

    axios.put(`/api/returns/status/claimed`, {
      returnIds: (this.props.checked || []).map((i) => (parseInt(i))),
      driverId: this.state.selectedDriver,
    }).then((resp) => {
      this.setState({
        loading: false,
      })

      this.props.clearChecked();
      window.location = '/pending'; // force refreshes data
    }).catch(errorHandler);
  }

  render(){
    return <div>
      <Dialog isOpen={this.state.showAssign} onClose={this.toggleAssign} title="Assign to Driver">
        <div className={Classes.DIALOG_BODY}>
          Assign {this.props.checked.length} items to:
          <HTMLSelect onChange={(e) => (this.setState({selectedDriver: e.currentTarget.value}))} value={this.state.selectedDriver}>
            <option></option>
            {this.state.drivers.map((d) => (
              <option value={d.id}>{d.email}</option>
            ))}
          </HTMLSelect>
        </div>
        <div className={Classes.DIALOG_FOOTER}>
          <div className={Classes.DIALOG_FOOTER_ACTIONS}>
            {this.state.loading && <Spinner size={15}/>}
            <Button intent="primary" text="Assign" disabled={!this.state.selectedDriver || this.state.loading} onClick={this.doSelect}/>
          </div>
        </div>
      </Dialog>
      <Button disabled={!(this.props.checked || []).length} intent="primary" text="Assign" onClick={this.toggleAssign}/>
    </div>
  }
}
