import React from 'react';

import { Spinner, InputGroup, Button, Callout } from '@blueprintjs/core';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';

import { Formik } from 'formik';
import * as Yup from 'yup';

import axios from 'axios';

export default class DriverRegister extends React.Component {
  state = {
    loading: false,
    error: null,
  }

  loginUser = (email, password) => {
    this.setState({
      loading: true,
    })

    axios.post('/api/drivers/login', {
      email,
      password,
    }).then((resp) => {
      // do redirect this.props.setToken(resp.data.token);
      this.setState({
        loading: false
      });

      window.location = '/';
    }).catch((e) => {
      if (e.response && e.response.data.error) {
        this.setState({
          error: e.response.data.error,
          loading: false,
        })
      }
      console.warn(e);
    });
  }

  render() {
    const { loading } = this.state;

    return <Formik
      initialValues={{email: '', password: ''}}
      onSubmit={(values) => {
        this.loginUser(values.email, values.password);
      }}
      validationSchema={Yup.object().shape({
        email: Yup.string()
          .min(1)
          .required('Required'),
        password: Yup.string(),
      })}
    >
      {props => {
        const {
          values,
          touched,
          errors,
          dirty,
          isSubmitting,
          setSubmitting,
          handleChange,
          handleBlur,
          handleSubmit,
          handleReset,
        } = props;
        return <Container style={{margin: 20}}>
          <Row>
            <Col>
              <Container>
                Admin Login
              </Container>
            </Col>
          </Row>
          <Row>
            <Col>
              { this.state.error && <Callout intent="danger">{this.state.error} <Button minimal icon="cross" onClick={() => (setSubmitting(false))}/></Callout>}
            </Col>
          </Row>
          <Row style={{marginTop: 20, maxWidth: 500}}>
            <Col>
              <form>
                <Container>
                  <InputGroup 
                    disabled={loading} 
                    id="email" 
                    placeholder="Email" 
                    onChange={handleChange} 
                    value={values.email}
                  />
                </Container>
                <Container>
                  <InputGroup 
                    disabled={loading} 
                    id="password"
                    placeholder="Password" 
                    type="password"
                    onChange={handleChange} 
                    value={values.password}
                  />
                </Container>
                <Container style={{marginTop: 20}}>
                  <Button type="submit" intent="primary" disabled={loading} onClick={handleSubmit}>Login</Button> 
                </Container>
              </form>
            </Col>
          </Row>
        </Container>
      }}
    </Formik>
  }
}
