import React from 'react';

import { Router, Route, Switch } from 'react-router';
import { Redirect, Link } from 'react-router-dom';

import { createBrowserHistory } from 'history';

import DriverCancellationComponent from './DriverCancellation';
import SeedComponent from './Seed';
import AdminLoginComponent from './AdminLogin';

import '@blueprintjs/core/lib/css/blueprint.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import BatchAssignComponent from './BatchAssign';
import BatchActionComponent from './BatchAction';
import BatchEmailComponent from './BatchEmail';
import { Navbar, Button } from '@blueprintjs/core';
import { Container } from 'react-bootstrap';
import BatchRescheduleComponent from './BatchReschedule';

const history = new createBrowserHistory();


function TopMenu({
  needsConfirmationDisable,
  pendingDisable,
  dispatchedDisable,
  claimedDisable,
  cancelledDisable,
  userCancelledDisable,
  failedDisable,
  children
}) {
  const smallMargin = { margin: '0 5px' };

  return <Navbar>
    <Navbar.Group>
      <Navbar.Heading>Statuses</Navbar.Heading>
      <Navbar.Divider style={smallMargin}/>
      {needsConfirmationDisable ? <div>Needs Confirmation</div> : <Link to="/needs_confirmation" disabled={true}>Needs Confirmation</Link>}
      <Navbar.Divider style={smallMargin}/>
      {pendingDisable ? <div>Pending</div> : <Link to="/pending" disabled={true}>Pending</Link>}
      <Navbar.Divider style={smallMargin}/>
      {claimedDisable ? <div>Claimed</div> : <Link to="/claimed">Claimed</Link>}
      <Navbar.Divider style={smallMargin}/>
      {dispatchedDisable ? <div>Dispatched</div> : <Link to="/dispatched">Dispatched</Link>}
      <Navbar.Divider style={smallMargin}/>
      {cancelledDisable ? <div>Driver Cancelled</div> : <Link to="/driver_cancelled">Driver Cancelled</Link>}
      <Navbar.Divider style={smallMargin}/>
      {userCancelledDisable ? <div>User Cancelled</div> : <Link to="/cancelled">User Cancelled</Link>}
      <Navbar.Divider style={smallMargin}/>
      {failedDisable ? <div>Failed</div> : <Link to="/failed">Failed</Link>}
      <Navbar.Divider style={smallMargin}/>
    </Navbar.Group>
    <Navbar.Group align="right">
      {children}
    </Navbar.Group>
  </Navbar>
}

class App extends React.Component {

  render() {
    return <Router history={history}>
      <Switch>
        <Route
          path="/needs_confirmation"
          exact
          render={(props) => {
            return <DriverCancellationComponent status={['needs_confirmation']}>
              {(props) => {
                return <div>
                  <TopMenu needsConfirmationDisable={true}>
                    <div style={{display: 'flex'}}>
                      <div>
                        <BatchAssignComponent {...props}/>
                      </div>
                    </div>
                  </TopMenu>
                </div>
              }}
            </DriverCancellationComponent>
          }}
        />
        <Route
          path="/pending"
          exact
          render={(props) => {
            return <DriverCancellationComponent status={['pending']}>
              {(props) => {
                return <div>
                  <TopMenu pendingDisable={true}>
                    <div style={{display: 'flex'}}>
                      <div>
                        <BatchAssignComponent {...props}/>
                      </div>
                      <div style={{paddingLeft: 10}}>
                        <BatchActionComponent
                          {...props}
                          status='cancelled'
                          redirect='/claimed'
                          text="Cancel (User)"
                        />
                      </div>
                      <div style={{paddingLeft: 10}}>
                        <BatchRescheduleComponent {...props}/>
                      </div>                      
                    </div>
                  </TopMenu>
                </div>
              }}
            </DriverCancellationComponent>
          }}
        />
        <Route
          path="/claimed"
          exact
          render={(props) => {
            return <DriverCancellationComponent status={['claimed']}>
              {(props) => {
                return <div>
                  <TopMenu claimedDisable={true}>
                    <div style={{display: 'flex'}}>
                      <div>
                        <BatchActionComponent
                          {...props}
                          status='pending'
                          redirect='/claimed'
                          text="Unclaim"
                        />
                      </div>
                      <div style={{paddingLeft: 10}}>
                        <BatchActionComponent
                          {...props}
                          status='cancelled'
                          redirect='/claimed'
                          text="Cancel (User)"
                        />
                      </div>
                    </div>
                    {
                      // <BatchActionComponent 
                      //   {...props}
                      //   status='dispatched'
                      //   redirect='/claimed'
                      //   text="Dispatch (Debug)"
                      // />
                    }
                  </TopMenu>
                </div>
              }}
            </DriverCancellationComponent>
          }}
        />
        <Route
          path="/dispatched"
          exact
          render={(props) => {
            return <DriverCancellationComponent status={['dispatched']}>
              {(props) => {
                return <div>
                  <TopMenu dispatchedDisable={true}>
                    <BatchActionComponent
                      {...props}
                      status='driver_cancelled'
                      redirect='/dispatched'
                      text="Cancel (Driver)"
                    />
                  </TopMenu>
                </div>
              }}
            </DriverCancellationComponent>
          }}
        />        
        <Route
          path="/driver_cancelled"
          exact
          render={(props) => {
            return <DriverCancellationComponent status={['driver_cancelled']}>
              {(props) => {
                return <div>
                  <TopMenu cancelledDisable={true}>
                    <div style={{display: 'flex'}}>
                      <div>
                        <BatchAssignComponent {...props}/>
                      </div>
                      <div style={{paddingLeft: 10}}>
                        <BatchEmailComponent redirect='/driver_cancelled' {...props}/>
                      </div>
                    </div>                    
                  </TopMenu>
                </div>
              }}
            </DriverCancellationComponent>
          }}
        />
        <Route
          path="/cancelled"
          exact
          render={(props) => {
            return <DriverCancellationComponent status={['cancelled']}>
              {(props) => {
                return <div>
                  <TopMenu userCancelledDisable={true}>
                    <BatchEmailComponent redirect='/cancelled' {...props}/>
                  </TopMenu>
                </div>
              }}
            </DriverCancellationComponent>
          }}
        />
        <Route
          path="/failed"
          exact
          render={(props) => {
            return <DriverCancellationComponent status={['failed']}>
              {(props) => {
                return <div>
                  <TopMenu failedDisable={true}>
                    <BatchEmailComponent redirect='/failed' {...props}/>
                  </TopMenu>
                </div>
              }}
            </DriverCancellationComponent>
          }}
        />
        <Route
          path="/seed"
          exact
          render={(props) => {
            return <SeedComponent />
          }}
        />        
        <Route
          path="/login"
          exact
          render={(props) => {
            return <AdminLoginComponent />
          }}
        />
        <Route
          path="/"
          render={(props) => {
            return <Redirect to="/needs_confirmation"/>
          }}
        />
      </Switch>
    </Router>
  }
}

export default App;
