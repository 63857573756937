import React from 'react';

import { Button, Spinner } from '@blueprintjs/core';
import axios from 'axios';

import errorHandler from './errorHandler';

export default class BatchActionComponent extends React.Component {

  state = {
    loading: false,
  }

  cancel = () => {
    this.setState({
      loading: true,
    });

    axios.put(`/api/returns/status/${this.props.status}`, {
      returnIds: (this.props.checked || []).map((i) => (parseInt(i))),
    }).then((resp) => {
      this.setState({
        loading: false,
      })

      this.props.clearChecked();
      window.location = this.props.redirect; // force refreshes data
    }).catch(errorHandler);
  }

  render() {
    return <Button 
      leftIcon={this.state.loading && <Spinner size={15}/>} 
      intent="primary" 
      text={this.props.text}
      disabled={!(this.props.checked || []).length} 
      onClick={this.cancel}
    />
  }
}