import React from 'react';

import axios from 'axios';
import { Container, Row, Col } from 'react-bootstrap';

import { Formik } from 'formik';
import * as Yup from 'yup';

import { 
  Spinner, 
  InputGroup, 
  Button, 
  Callout,
  HTMLTable,
} from '@blueprintjs/core';

function errorHandler(e) {
  if(e.response.status === 401 || e.response.status === 403) {
    window.location = '/login';
  }
}

export default class SeedComponent extends React.Component {
  componentDidMount () {
    // check for login
    axios.get(`/api/returns/all-admin`).catch(errorHandler);
  }

  seedReturns = (dropoffId, phoneNumber, email) => {
    axios.post(`/api/dropoffs/${dropoffId}/test-returns`, {
      phone_number: phoneNumber,
      email,
      timezone: 'EST',
    }).then((resp) => {
      window.location = '/'
    }).catch(errorHandler);
  }

  render() {

    return <Container>
      <Formik
        initialValues={{dropoffId: null, phoneNumber: null, email: null}}
        onSubmit={(values) => {
          console.warn('tset')
          this.seedReturns(values.dropoffId, values.phoneNumber, values.email);
        }}
        validationSchema={Yup.object().shape({
          email: Yup.string()
            .min(1)
            .required('Required'),
          phoneNumber: Yup.string()
            .min(1)
            .required('Required'),
          dropoffId: Yup.string()
            .min(1)
            .required('Required'),
        })}
      >
        {props => {
          const {
            values,
            touched,
            errors,
            dirty,
            isSubmitting,
            setSubmitting,
            handleChange,
            handleBlur,
            handleSubmit,
            handleReset,
          } = props;        
          return <Container>
            <form>
              <Row>
                <Col>
                  <InputGroup 
                    disabled={false} 
                    id="dropoffId"
                    placeholder="Dropoff Id"
                    onChange={handleChange} 
                    value={values.dropoffId}
                  />
                </Col>
              </Row>
              <Row>
              <Col>
                <InputGroup 
                  disabled={false} 
                  id="phoneNumber"
                  placeholder="Phone Number"
                  onChange={handleChange} 
                  value={values.phoneNumber}
                />
                </Col>              
              </Row>
              <Row>
                <Col>
                  <InputGroup 
                    disabled={false} 
                    id="email"
                    placeholder="Email"
                    onChange={handleChange} 
                    value={values.email}
                  />
                </Col>            
              </Row>
              <Row>
                <Col>
                  <Button type="submit" intent="primary" onClick={handleSubmit}>Seed</Button>
                </Col>
              </Row>
            </form>
          </Container>
        }}
      </Formik>      
    </Container>
  }

}