export default function errorHandler(e) {
  console.warn(e)
  if (e.response) {
    if(e.response.status === 401 || e.response.status === 403) {
      window.location = '/login';
    } else {
      if (this) {
        this.setState({
          error: e.response.data,
          loading: false,
        });
      }
    }
  }
}
