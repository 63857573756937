import React from 'react';

import { 
  Checkbox,
  Spinner, 
  InputGroup, 
  Button,
  Dialog,
  Callout,
  HTMLSelect,
  Classes,
} from '@blueprintjs/core';

import { DatePicker, TimePicker } from '@blueprintjs/datetime';
import "@blueprintjs/datetime/lib/css/blueprint-datetime.css";

import axios from 'axios';

import errorHandler from './errorHandler';

export default class BatchRescheduleComponent extends React.Component {

  state = {
    showReschedule: false,
    date: null,
    time: 9,
    selectedDriver: null,
  }

  toggleReschedule = () => {
    this.setState(({showReschedule}) => ({
      showReschedule: !showReschedule
    }))
  }

  doReschedule = (e) => {

    this.setState({
      loading: true,
    });

    // adjust to utc midnight
    const adjustedDate = this.state.date.getTime() - (this.state.date.getTimezoneOffset() * 60 * 1000);
    console.warn(new Date(adjustedDate).toString())

    axios.post(`/api/returns/batch-reschedule`, {
      returnIds: (this.props.checked || []).map((i) => (parseInt(i))),
      date: adjustedDate,
      time: this.state.time,
    }).then((resp) => {
      this.setState({
        loading: false,
      })

      this.props.clearChecked();
      window.location = '/pending'; // force refreshes data
    }).catch(errorHandler.bind(this));
  }

  render(){
    return <div>
      <Dialog isOpen={this.state.showReschedule} onClose={this.toggleReschedule} title="Reschedule pickups">
        { this.state.error && <Callout intent="danger">{this.state.error.message}</Callout>}
        <div className={Classes.DIALOG_BODY}>
          Reschedule {this.props.checked.length} items to:
          <DatePicker
            onChange={(date) => (this.setState({date}))}
            value={this.state.date}
          />
          {
          <HTMLSelect onChange={(e) => (this.setState({time: e.currentTarget.value}))} value={this.state.time}>
            <option value={9}>9AM-1PM</option>
            <option value={13}>1PM-5PM</option>
          </HTMLSelect>
          }
          <div style={{marginTop: 20}}><i>Note: this will reschedule to the selected time in the user's timezone.</i></div>
        </div>
        <div className={Classes.DIALOG_FOOTER}>
          <div className={Classes.DIALOG_FOOTER_ACTIONS}>
            {this.state.loading && <Spinner size={15}/>}
            <Button intent="primary" text="Reschedule" disabled={!this.state.time || !this.state.date || this.state.loading} onClick={this.doReschedule}/>
          </div>
        </div>
      </Dialog>
      <Button disabled={!(this.props.checked || []).length} intent="primary" text="Reschedule" onClick={this.toggleReschedule}/>
    </div>
  }
}
