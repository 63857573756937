import React, { createRef } from 'react';

import axios from 'axios';

import { Container, Row, Col, Navbar } from 'react-bootstrap';

import { 
  Checkbox,
  Spinner, 
  InputGroup, 
  Button, 
  Callout,
  Divider,
  HTMLTable,
  ControlGroup,
  HTMLSelect,
  Classes,
} from '@blueprintjs/core';

import _ from 'lodash';

import errorHandler from './errorHandler';

export default class DriverCancellationComponent extends React.Component {
  state = {
    results: null,
    checked: {},
    filterField: 'userId',
    filteredResults: null,
  }

  filterRef = createRef(null);

  filterFields = {
    userId: (res) => res.id,
    userEmail: (res) => res.guest_email,
    driverEmail: (res) => res.driver?.email,
  }

  refresh = (statuses) => {
    const statusQ = statuses.map((s) => (`status=${s}`)).join('&');
    axios.get(`/api/returns/all-admin?${statusQ}`).then((resp) => {
      this.setState({
        results: resp.data.results,
      })
    }).catch(errorHandler);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.status !== this.props.status) {
      this.clearSelected();
      this.refresh(this.props.status || []);
      this.clearFilteredResults();
    }
  }

  componentDidMount(){
    this.refresh(this.props.status || []);
  }

  clearSelected = () => {
    this.setState({
      checked: {},
    });
  }

  setSelected = (id, isChecked) => {
    this.setState(({checked}) => ({
      checked: {
        ...checked,
        [id]: isChecked,
      }
    }))
  }

  setFilterField = ({ target }) => {
    this.setState({ filterField: target.value })
  }

  applyFilter = () => {
    const { results, filterField } = this.state;

    this.setState({
      filteredResults: results.filter((res) => {
        const fieldData = this.filterFields[filterField](res);
        return fieldData && String(fieldData).match(this.filterRef.current.value);
      })
    }, () => {
      this.filterRef.current.value = ''
    })
  }

  clearFilteredResults = () => {
    this.setState({ filteredResults: null });
  }

  render() {
    if (!this.state.results) {
      return <Spinner />
    } 

    const { checked, results, filteredResults } = this.state;

    const res = filteredResults || results;

    const rows = res.map((res) => {
      return <tr key={res.id}>
        <td><Checkbox onChange={(e) => (this.setSelected(res.id, e.target.checked))} value={checked[res.id]}/></td>
        <td>{res.id}</td>
        <td>{res.client_id}</td>
        <td>{res.status}</td>
        <td>{res.address.formatted}</td>
        <td><a href={`mailto:${res.guest_email}`}>{res.guest_email}</a></td>
        <td>{res.driver && res.driver.email}</td>
        <td>{new Date(res.pickup_window_start).toString()}</td>
      </tr>
    });

    const checkedList = Object.keys(checked).filter((i) => (checked[i]))

    const filterSelectOptions = [
      { value: 'userId', label: 'ID' },
      { value: 'userEmail', label: 'User E-mail' },
      { value: 'driverEmail', label: 'Driver' },
    ];

    return <div>
      <Container>
        {this.props.children({checked: checkedList, clearChecked: this.clearSelected})}
        <ControlGroup style={{ padding: '20px 0' }} fill>
          <HTMLSelect 
            options={filterSelectOptions}
            onChange={this.setFilterField}
            className={Classes.FIXED}
          >
          </HTMLSelect>
          <InputGroup placeholder="keyword" inputRef={this.filterRef}/>
          <Button icon="filter" onClick={this.applyFilter}  className={Classes.FIXED}>Filter</Button>
          <Button onClick={this.clearFilteredResults}  className={Classes.FIXED}>Remove Filters</Button>
        </ControlGroup>
        <HTMLTable>
          <thead>
            <tr>
              <th>{' '}</th><th>ID</th><th>PartnerID</th><th>status</th><th>Address</th><th>User E-mail</th><th>Driver</th><th>Pickup Time</th>
            </tr>
          </thead>
          <tbody>
            {rows}
          </tbody>
        </HTMLTable>
      </Container>
    </div>
  }
}
