import React from 'react';

import { 
  Checkbox,
  Spinner, 
  InputGroup, 
  Button,
  Dialog,
  Callout,
  HTMLSelect,
  Classes,
  EditableText,
  TextArea,
} from '@blueprintjs/core';

import axios from 'axios';

import errorHandler from './errorHandler';

export default class BatchAssignComponent extends React.Component {

  state = {
    subject: '',
    message: '',
    showBatchEmail: false,
  }

  toggleBatchEmail = () => {
    this.setState(({showBatchEmail}) => ({
      showBatchEmail: !showBatchEmail
    }))
  }

  doEmail = (e) => {

    this.setState({
      loading: true,
    });

    axios.put(`/api/returns/batch-email`, {
      returnIds: (this.props.checked || []).map((i) => (parseInt(i))),
      subject: this.state.subject,
      message: this.state.message,
    }).then((resp) => {
      this.setState({
        loading: false,
      })

      this.props.clearChecked();
      window.location = this.props.redirect; // force refreshes data
    }).catch(errorHandler);
  }

  render(){
    return <div>
      <Dialog isOpen={this.state.showBatchEmail} onClose={this.toggleBatchEmail} title="Send Batch Email">
        <div className={Classes.DIALOG_BODY}>
          <InputGroup 
            placeholder="Subject" 
            onChange={(e) => (this.setState({subject: e.target.value}))} 
            value={this.state.subject}
          />
          <TextArea 
            placeholder="Message" 
            onChange={(e) => (this.setState({message: e.target.value}))} 
            type="textarea"
            fill
            growVertically
            value={this.state.message}
          />
        </div>
        <div className={Classes.DIALOG_FOOTER}>
          <div className={Classes.DIALOG_FOOTER_ACTIONS}>
            {this.state.loading && <Spinner size={15}/>}
            <Button intent="primary" text="Email" disabled={!this.state.subject.length || !this.state.message.length} onClick={this.doEmail}/>
          </div>
        </div>
      </Dialog>
      <Button disabled={!(this.props.checked || []).length} intent="primary" text="Email" onClick={this.toggleBatchEmail}/>
    </div>
  }
}
